.war-battle {
    width: 900px;
}

@media screen and (max-width: 920px) {
    .war-battle {
        width: 100%;
    }
}

.paragraph--secondary {
    color: var(--secondary-text-color);
}
