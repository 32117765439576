.video-player {
  background-color: var(--main-bg-color);
  position: relative;
  width: 100%;
}

.video-player::before {
  content: '';
  display: block;
  height: 0;
  padding-bottom: 56.25%;
}

.video-player__cover {
  overflow: hidden;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;

  img {
    width: 100%;
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.video-player__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate3d(-50%, -50%, 0);
}

.video-player__cover,
.iframe-video {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

iframe {
  z-index: 1;
}
