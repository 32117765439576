.container--error-page {
    align-items: center;
    background: #000; // match image
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    font-family: SometypeMono, sans-serif;
    font-size: var(--font-size-normal);
    gap: var(--x-large-margin);
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.defeated-image {
    align-self: center;
}
