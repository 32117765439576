.landing-page__section--leaderboard {
  .main-logo {
    margin-right: auto;
  }

  .levels {
    margin-top: calc(var(--x-large-margin) * 3);

    @media screen and (max-width: 620px) {
      margin-top: 0;
    }
  }
}

.leaderboard__pagination {
    align-items: center;
    display: flex;
    gap: var(--large-margin);
    justify-content: flex-end;
    padding: var(--large-margin) var(--large-margin) 0;
    width: 100%;
}

.leaderboard__btn__navigation {
    color: white;
    font-size: 32px;
}

.leaderboard__btn__navigation:disabled {
    opacity: 0.5;
}

.checkboxes__title {
    font-size: 16px;
    margin-bottom: var(--small-margin);
    text-align: left;
}

.filters__subtitle {display: none;}
.checkbox-filter {
    display: grid;
    grid-gap: 4rem;
    grid-template-areas: 'a a a a';
}

.checkboxes {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
    background-position: 0 0;
    background-repeat: repeat-x;
    padding-top: var(--large-margin);
    max-width: 100%;
    overflow-x: auto;
}

.filters {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
    background-position: 0 100%;
    background-repeat: repeat-x;
    padding-bottom: calc(var(--medium-margin) * 2);
    width: 100%;
}

.filters__title {
  font-family: Amarante, cursive;
  font-size: 24px;
  text-align: left;
}

@media (max-width: 900px) {
  .checkbox-filter {
    grid-template-areas: 'a';
    gap: 2rem;
  }

  .checkboxes {
    background-image: none;
    padding-top: 0;
  }

  .filters__title {
    margin-bottom: var(--large-margin);
  }

  .filters__subtitle,
  .checkboxes__title {
    display: block;
    font-size: 10px;
    opacity: 0.5;
  }

  .leaderboard__pagination {
    gap: var(--small-margin);
    justify-content: space-between;
    padding: 0;
}

  .leaderboard__btn__navigation {
    font-size: 14px;
  }
}
