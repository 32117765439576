.hero-banner {
  position: relative;
  min-height: 400px;
  top: -40px;

  .hero-banner__item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    &:nth-child(1) {
      left: -90px;
    }
    &:nth-child(2) {
      left: -10px;
    }
    &:nth-child(3) {
      left: 50px;
    }
    &:nth-child(4) {
      top: -20px;
      left: 170px;
    }
    &:nth-child(5) {
      left: 130px;
      z-index: 0;
    }
    &:nth-child(6) {
      top: -40px;
      right: 160px;
      transform: scale(-1.2, 1.2);
    }
    &:nth-child(7) {
      top: 5px;
      right: 50px;
    }
    &:nth-child(8) {
      right: -90px;
    }
    &:nth-child(9) {
      top: -8px;
      right: -80px;
    }
  }

  .hero-banner__item--flip {
    transform: scaleX(-1);
  }

  img {
    height: 100%;
  }
}


@media screen and (max-width: 480px) {
  .hero-banner {
    min-height: 200px;

    .hero-banner__item {
      &:nth-child(1) {
        left: -120px;
      }
      &:nth-child(2) {
        left: -70px;
      }
      &:nth-child(3) {
        left: 30px;
      }
      &:nth-child(4) {
        top: -8px;
        left: -20px;
      }
      &:nth-child(5) {
        left: -30px;
      }
      &:nth-child(6) {
        top: -20px;
        right: 10px;
      }
      &:nth-child(7) {
        right: -60px;
      }
      &:nth-child(8) {
        right: -130px;
      }
      &:nth-child(9) {
        top: -4px;
        right: -110px;
      }
    }
  }
}
