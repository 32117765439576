.coming-soon {
    animation: bg 10s ease-out forwards;
    background-image: url('/bg.png');
    background-position: center 10%;
    background-size: cover;
    min-height: 100vh;
    position: relative;
}

.coming-soon-image {
    object-fit: cover;
}

.bg-mask {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    left: 0;
    min-height: 100vh;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    @supports (mix-blend-mode: overlay) {
        mix-blend-mode: overlay;
    }
}

.content-wrapper {
    padding: 12px 32px;
    position: relative;
}

.coming-soon-nav {
    margin-top: 16px;
}

.coming-soon-nav__item:not(:first-child) {
    margin-inline-start: 32px;
}

.coming-soon-nav svg {
    fill: rgba(255, 255, 255, 0.8);
    height: 32px;
    width: 32px;
}

.coming-soon svg:hover {
    fill: #FFF;
}

.coming-soon-hero {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
}

.coming-soon-hero .heading {
    color: #FFF;
    font-family: DPComic, sans-serif;
    font-size: 6rem;
    letter-spacing: 10px;
    line-height: 6rem;
    text-align: center;
    text-shadow: -0.0075em 0.0075em 0 mix(#FFF, #16E951, 94%),
    0.005em 0.005em 0 mix(#FFF, #16E951, 60%),
    0.01em 0.01em 0 mix(#FFF, #16E951, 62%),
    0.015em 0.015em mix(#FFF, #16E951, 64%),
    0.02em 0.02em 0 mix(#FFF, #16E951, 66%),
    0.025em 0.025em 0 mix(#FFF, #16E951, 68%),
    0.03em 0.03em 0 mix(#FFF, #16E951, 70%),
    0.035em 0.035em 0 mix(#FFF, #16E951, 72%);
    text-transform: uppercase;
}

@keyframes bg {
    0% {
        background-position-y: 10%;
    }

    100% {
        background-position-y: 100%;
    }
}

.coming-soon .about {
    color: #FFF;
    font-size: 18px;
    line-height: 24px;
    margin: 30px auto;
    max-width: 1200px;
    text-align: center;
}

.coming-soon-hero .coming-soon-button {
    appearance: none;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid #16E951;
    color: #FFF;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 10px 48px;
    -webkit-tap-highlight-color: transparent;
    text-shadow: 0 1px 1px #16E951;
}


@media screen and (min-width: 414px) {
    .coming-soon-hero .heading {
        font-size: 10rem;
        line-height: 10rem;
    }

    .coming-soon-hero {
        margin-top: 10rem;
    }

    .coming-soon-hero .coming-soon-button {
        font-size: 30px;
    }
}
