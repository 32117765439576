
:root {
    --main-bg-color: #04070F;
    --error-bg-color: #F00; //shares with red-color
    --success-bg-color: #0F0;
    --syndicate-color-water: #6DD3FF;
    --syndicate-color-timber: #33F899;
    --syndicate-color-metal: #C1A4FF;
    --syndicate-color-earth: #FF9635;
    --syndicate-color-fire: #FF6645;
    --syndicate-color-spirit: #FFF;
    --syndicate-color-renegade: #333;
    --yellow-color: #FFE600;
    --red-color: #F00; //shares with error-bg-color
    --secondary-text-color: rgb(153, 153, 153);
    --text-color: #FFF;
    --x-small-margin: 5px;
    --small-margin: 10px;
    --medium-margin: 20px;
    --large-margin: 30px;
    --x-large-margin: 60px;
    --large-negative-margin: -30px;
    --x-large-negative-margin: -60px;
    // --max-width-container: 1200px;
    --max-width-container: 1440px;
}
