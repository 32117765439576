.carousel {
    outline: none;
}

.flickity-page-dots {
    display: flex;
    gap: var(--medium-margin);
    justify-content: center;
    margin: var(--large-margin);
}

.dot {
    cursor: pointer;
    padding: var(--small-margin);
}

.dot::before {
    background: var(--text-color);
    content: '';
    display: block;
    height: 6px;
    opacity: 0.5;
    transform: rotate(45deg);
    transition: opacity 0.2s ease-in-out;
    width: 6px;
}

.dot.is-selected::before {
    opacity: 1;
}
