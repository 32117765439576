.diamond {
    display: flex;
    justify-content: center;
}

.diamond__inner {
    background-color: var(--text-color);
    height: 6px;
    transform: rotate(45deg);
    width: 6px;
}
