.checkbox-button-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    &--1 {
        grid-template-columns: repeat(1, 1fr);
    }

    &--3 {
        grid-auto-flow: column;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, auto);
    }
}

.checkbox-button-grid:not(:last-child) {
    margin-bottom: 2rem;
}

.checkbox-button input[type='checkbox'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox-button-nbr {
    opacity: 0.3;
}

.checkbox-button {
    text-align: left;
}

.checkbox-button input[type='checkbox'] + label {
    border: 1px solid #FFF;
    border-radius: 99em;
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
}

.checkbox-button input[type='checkbox']:hover + label,
.checkbox-button input[type='checkbox']:focus + label {
    background-color: #FFF;
    color: #000;
    cursor: pointer;
}


.checkbox-button input[type='checkbox']:checked + label,
.checkbox-button input.is-active + label {
    background-color: #FFF;
    color: #000;

    // cursor: default;
}

// ensure last
.checkbox-button input.not-active[type='checkbox']:checked + label {
    background-color: blue;
}

.checkbox-button input:not(:checked):focus + label,
.checkbox-button input:not(:checked):hover + label {
    background-color: black !important;

    // background-color: black;
    color: white;
}

.checkbox-button input.disable + label {
    opacity: 0.5;
    pointer-events: none;
}

.checkbox-button input[type='checkbox']:disabled + label {
    cursor: not-allowed;
    opacity: 0.4;
}


@media (max-width: 900px) {
    .checkbox-button-grid,
    .checkbox-button-grid--1,
    .checkbox-button-grid--3 {
      grid-template-columns: repeat(7, minmax(130px, 1fr));
      grid-auto-flow: row;
      grid-template-rows: inherit;
    }
}

.input {
    appearance: none;
    background-color: transparent;
    border: 1px solid var(--text-color);
    color: inherit;
    height: 2rem;
    padding: 0 .6666666667rem;
    width: 100%;
}

.input:focus,
.input:active {
    color: var(--text-color);
    outline: none;
}

.input::placeholder {
    color: white;
    opacity: 0.75;
    transition: opacity  0.3s ease-in-out;
}

.field-oneline {
    display: inline-block;
    position: relative;
    max-width: 500px;
}

.field-oneline__input {
  grid-column: 1;
  grid-row: 1;
}

.radio-button-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.radio-button-grid:not(:last-child) {
  margin-bottom: 2rem;
}

// --------------------------------------------------------
// Radio Button
// --------------------------------------------------------
.radio-button input[type='radio'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.radio-button input[type='radio'] + label {
  border: 1px solid #FFF;
  border-radius: 99em;
  cursor: pointer;
  display: block;
  padding: 0.5em 2em;
  text-align: center;
  width: 100%;
}

.radio-button input[type='radio']:hover + label,
.radio-button input[type='radio']:focus + label {
  background-color: #FFF;
  color: #000;
}

.radio-button input[type='radio']:checked + label {
  background-color: #FFF;
  color: #000;
  cursor: default;
}

.radio-button input[type='radio']:disabled + label {
  cursor: not-allowed;
  opacity: 0.4;
}


// --------------------------------------------------------
// Avatar Form
// --------------------------------------------------------
.form--inline {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 1240px) {
    flex-direction: column;
  }
}

.form--avatar {
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

// Fields Column
.form--avatar__fields {
  grid-column: auto / span 5;
  border: none;
  padding: 0;
  margin: 0;

  @media (max-width: 1240px) {
    margin-bottom: var(--space-l);
    grid-column: auto / span 12;
  }
}

.form--avatar__fields .label {
  margin-bottom: 1rem;
}

// Column with preview of avatar and Download button
.form--avatar__media {
  grid-column: 4 / span 2;

  @media (max-width: 1240px) {
    grid-column: auto / span 12;
    text-align: center;
  }
}

.arrows {
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
}

.arrows .button {
  font-size: 1.2rem;
  padding: 0.2em 1em 0.3em;
}

.form--avatar__media .button[type="submit"] {
  @media (min-width: 1240px) {
    margin-left: 39px;
  }
}

// Contains avatar image preview
.form--avatar__preview {
  border: 1px solid #FFF;
  height: 200px;
  width: 200px;

  @media (max-width: 1240px) {
      margin-left: auto;
      margin-right: auto;
    }
}

.canvas {
  width: 100%;
  height: auto;
}
// --------------------------------------------------------
// BG Button Inputs
// --------------------------------------------------------
.radio-button.radio-button--bg input[type='radio'] + label {
  background-color: var(--avatar-bg);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 24px;
  padding: 0;
  width: 24px;
}

.radio-button.radio-button--bg.is-water {
 --avatar-bg: #6DD3FF;
}

.radio-button.radio-button--bg.is-timber {
  --avatar-bg: #33F899;
}

.radio-button.radio-button--bg.is-metal {
  --avatar-bg: #C1A4FF;
}

.radio-button.radio-button--bg.is-earth {
  --avatar-bg: #FF9635;
}

.radio-button.radio-button--bg.is-fire {
  --avatar-bg: #FF6645;
}

// .radio-button.radio-button--bg  input[type='radio']:hover + label,
// .radio-button.radio-button--bg  input[type='radio']:focus + label {
//   background-color: var(--button-hover-bg-color);
//   color: var(--button-hover-text-color);
// }

.radio-button.radio-button--bg input[type='radio']:checked + label {
  cursor: default;
  outline: 1px solid #FFF;
}

.radio-button.radio-button--bg input[type='radio']:disabled + label {
  cursor: not-allowed;
  opacity: 0.4;
}

.form--signup input[type='checkbox'] {
  color-scheme: dark;
  margin-right: 12px;
}

.form--signup input[type='checkbox']:checked {
  accent-color: white;
}
