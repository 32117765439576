.grid {
  display: grid;
  gap: 1rem;

  &--two {
    grid-template-columns: repeat(2, 1fr);
  }

  &--three {
    grid-template-columns: repeat(3, 1fr);
  }

  &--sides {
    grid-template-columns: repeat(4, 1fr);
    .grid__item:nth-child(2) {
      grid-column: span 2;
    }
  }

  &--four {
    grid-template-columns: repeat(4, 1fr);
  }

  &--five {
    grid-template-columns: repeat(5, 1fr);
  }

  &--seven {
    grid-template-columns: repeat(7, 1fr);
  }
}

.column {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .grid {
    &--two,
    &--five {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .grid {
    &--four {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 480px) {
  .grid {
    &--four,
    &--sides {
      grid-template-columns: 1fr;
    }

    &--sides {
      .grid__item:nth-child(2) {
        grid-column: 1fr;
      }
    }
  }
}
