.clouds-wrapper--landing {
  pointer-events: none;
  position: absolute;
  top: 80%;
}

@media screen and (max-width: 760px) {
  .clouds-wrapper--landing {
    bottom: 50%;
    top: 0;
  }
}

.clouds,
.clouds--hero {
  display: flex;
  left: 0;
  position: absolute;
  transform: translateX(-50%);
  pointer-events: none;
}

.clouds {
  bottom: calc(var(--x-large-margin) * 2);
  z-index: 0;
}

.clouds.clouds--hero {
  bottom: -160px;
}

.clouds.clouds--hero.clouds--front {
  // z-index: 1;
}

.clouds img {
  max-width: fit-content;
}

.clouds.clouds--back {
  animation: cloudsBack 220s linear forwards infinite;
  bottom: calc(var(--x-large-negative-margin) / 2);
  z-index: 0;
}

.clouds.clouds--front {
  animation: cloudsBack 180s linear forwards infinite;
  margin-top: -50px;
  z-index: 0;
}

@keyframes cloudsBack {
  0% {
      transform: translateX(-100%);
  }

  100% {
      transform: translateX(100%);
  }
}

@media screen and (max-width: 620px) {
  .clouds {
    display: none;
  }
}
