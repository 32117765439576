.container--landing-page-roadmap {
  .main-logo {
    margin-left: 0;
    margin-right: auto;
  }

  .section-title {
    font-family: 'Inter', sans-serif;
    text-align: left;
    font-size: 32px;
    width: 100%;
    margin: var(--x-large-margin) 0;
  }
}
