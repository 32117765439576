.timeline {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.timeline__item__header {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.timeline__item .title {
  font-family: Amarante, cursive;
  font-size: 32px;
  padding-right: 6rem;
}

.timeline__item .date,
.timeline__item .content {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.timeline__item .date {
  opacity: 0.5;
  font-weight: 600;
  padding-bottom: var(--large-margin);
}

.timeline__item .content  {
  padding-top: var(--large-margin);
  padding-right: 4rem;
  border-top: 3px solid #FFF;
  position: relative;
}

.timeline__item .content:before {
  content: '◆';
  font-size: 24px;
  position: absolute;
  top: -21px;
  left: -6px;
}

.landing-page__paragraph--timeline {
  text-align: left;
  margin-bottom: 1rem;
}


@media screen and (max-width: 900px) {
  .timeline {
    grid-template-columns: 1fr;
  }
}
