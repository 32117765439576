.modal__overlay {
  align-items: center;
  background: black;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 900;
}

// how to do transition, does not support animate (might be able to via react css transitions)
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.wrapper--newsletter {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  color: white;
}

.newsletter-spacing {
  margin-bottom: var(--x-large-margin); 
}

.newsletter-spacing-small {
  margin-bottom: var(--medium-margin); 
}

.newsletter-text {
  color: white;
}
