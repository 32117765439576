.faq {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;

  .faq__item {
    padding-top: var(--small-margin);
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
      background-repeat: repeat-x;
    }

    &:before {
      background-position: 0 0;
    }
  }

  .question {
    font-weight: bold;
  }
}

@media screen and (max-width: 820px) {
  .faq {
    grid-template-columns: repeat(2, 1fr);
  }
}
