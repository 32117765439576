.warrior {
    display: flex;

    // position: relative;
    width: 25%;
}

.warrior--loading {
    opacity: 0.5;
}

.warrior--west {
    justify-content: flex-end;
}

.warrior__body {
    display: flex;
    flex-direction: column;
}

.warrior--west h3 {
    justify-content: flex-end;
    text-align: right;
}

.warrior__attack__image {
    width: 58px;
}

.warrior__details {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--x-small-margin);
}

.warrior__details--score {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--small-margin);
}

.warrior__details--syndicate {
    flex: 1;
}

.warrior__details--syndicate span {
    background: var(--main-bg-color);
    border: 1px solid var(--text-color);
    border-radius: 20px;
    padding: 0 var(--small-margin);
}

.warrior__details--syndicate-water span {
    border-color: var(--syndicate-color-water);
    color: var(--syndicate-color-water);
}

.warrior__details--syndicate-timber span {
    border-color: var(--syndicate-color-timber);
    color: var(--syndicate-color-timber);
}

.warrior__details--syndicate-metal span {
    border-color: var(--syndicate-color-metal);
    color: var(--syndicate-color-metal);
}

.warrior__details--syndicate-earth span {
    border-color: var(--syndicate-color-earth);
    color: var(--syndicate-color-earth);
}

.warrior__details--syndicate-fire span {
    border-color: var(--syndicate-color-fire);
    color: var(--syndicate-color-fire);
}

.warrior__details--attack-bonus[data-id='water'] {
    color: var(--syndicate-color-water);
}

.warrior__details--attack-bonus[data-id='timber'] {
    color: var(--syndicate-color-timber);
}

.warrior__details--attack-bonus[data-id='metal'] {
    color: var(--syndicate-color-metal);
}

.warrior__details--attack-bonus[data-id='earth'] {
    color: var(--syndicate-color-earth);
}

.warrior__details--attack-bonus[data-id='fire'] {
    color: var(--syndicate-color-fire);
}

.warrior__details__stars {
  position: absolute;
  display: none;
}

@media screen and (min-width: 901px) {
  .warrior__details__stars {
    bottom: 20px;
    height: 400px;
    object-fit: contain;
    pointer-events: none;
    right: 50px;
    width: 400px;
    z-index: 10;
  }

  .warrior--east .warrior__details__stars {
      left: 50px;
      right: inherit;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
  }
}

.warrior__image {
    // bottom: -80px;
    height: 400px;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
    object-fit: contain;
    pointer-events: none;
    position: absolute;
    // right: 45px;
    width: 400px;
    z-index: 10;
}

.warrior__image--east {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}


.battle-result.battle-result--not-visible .warrior__image--east {
    left: 35px;
    right: auto;
}

@media screen and (max-width: 750px) {
  .battle-result.battle-result--not-visible .warrior__image--east,
  .battle-result.battle-result--not-visible .warrior__image {
    bottom: 0;
  }

  .battle-result.battle-result--not-visible .warrior__image--east {
    left: -10%;
  }

  .battle-result.battle-result--not-visible .warrior__image--west {
    right: -15%;
  }
}



// LEADERBOARDS LIST
.warriors {
  .warriors-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(9, minmax(130px, 1fr));
    margin-bottom: var(--large-margin);
    padding-top: var(--x-large-margin);
  }
}

.checkbox-button--image {
    position: relative;
    width: 100%;
}

.warrior__img {
    bottom: 0;
    max-width: 130px;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.checkbox-button--image:not(.checkbox-button--active):hover {
  .warrior__img {
    opacity: 0;
    visibility: hidden;
  }

  .warrior__img--hover {
    opacity: 1;
    visibility: visible;
  }
}

.warrior__img--hover {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 900px) {
  .warriors {
    max-width: 100%;
    overflow-x: auto;
    padding-top: 20px;

    .warriors-list {
      padding-top: var(--small-margin);
    }
  }
}
