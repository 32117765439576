.table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.table {
    width: 100%;

    thead tr,
    tbody tr {
        display: grid;
        gap: 2rem;
        grid-template-columns: 4% 4% repeat(1, minmax(130px, 1fr)) 4% 13% 4% 13% 13% repeat(1, minmax(130px, 1fr));
    }
}

.table th,
.table td {
    padding: 16px 0 14px;
    text-align: left;
}

.table th {
    border-bottom: 1px solid #FFF;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
}

.table th span {
    float: right;
    opacity: 0.5;
}

.table td {
    // dashed border with specialty spacing
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
    background-position: 0 100%;
    background-repeat: repeat-x;
    font-size: 16px;
}

.hidden {
    font-size: 0;
}

.hidden-value {
    &::before {
        content: attr(data-content);
    }
}

.table-filters {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    margin-bottom: var(--large-margin);
    width: 100%;
}

.table-filters__label,
.table-filters__btn button {
    text-align: left;
}

.table-filters__label {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
    background-position: 0 100%;
    background-repeat: repeat-x;
    font-size: 16px;
    margin-bottom: 8px;
    opacity: 0.4;
    padding-bottom: var(--x-small-margin);
    white-space: nowrap;
}

.table-filters__btn button {
    color: var(--text-color);
    font-size: 16px;
    line-height: 1.1;
    width: 100%;

    @media (max-width: 481px) {
        font-size: 18px;
        padding: 12px 8px;
    }
}

// For leaderboard
.wins-label {
    display: block;
    white-space: nowrap;
}

.table-filters__btn button.is-selected {
    background-color: #FFF;
    color: #000;
}

.table-filters__btn {
    flex: 1 0 auto;
    position: relative;
}

.table-filters__btn::before {
    background-position: 0 100%;
    top: 2px;
}

.table-filters__btn::after {
    background-position: 0 100%;
    bottom: 2px;
}


@media (max-width: 480px) {
    .table thead tr,
    .table tbody tr {
        gap: 1rem;
    }

    .table th span {
        display: none;
    }

    .table th,
    .table td {
        font-size: 14px;
    }
}

.table-link {
  text-decoration: underline;
}

.row-owned a,
.row-owned {
  color: var(--yellow-color);
}

.row-burned a,
.row-burned {
  color: var(--red-color);
}


// SOLID
.table--solid {
    border-spacing: 0;
    margin-top: 40px;
}

.table--solid thead tr,
.table--solid tbody tr {
    gap: 0;
    grid-template-columns: 80px 120px 120px repeat(4, 80px) minmax(500px, 1fr);
}

.table--solid th,
.table--solid td {
    display: flex;
    align-items: center;
    background-image: none;
}

.table--solid th:nth-child(n+4):not(:last-child),
.table--solid td:nth-child(n+4):not(:last-child) {
    justify-content: center;
}

.table--solid thead th:nth-child(n+3):not(:last-child),
.table--solid tbody td:nth-child(n+3):not(:last-child) {
    border-right: 1px solid #FFF;
}

.table--solid thead tr,
.table--solid tbody tr {
    border-bottom: 1px solid #FFF;
}

.table--solid thead th:not(:last-child),
.table--solid tbody td:not(:last-child) {
    padding-block: 8px;
}

.table--solid thead th:not(:first-child),
.table--solid tbody td:not(:first-child) {
    padding-inline: 8px;
}
