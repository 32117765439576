.battle-nav {
    display: flex;
    gap: var(--small-margin);
    margin: calc(var(--x-large-margin) * 3) 0 var(--x-large-margin);
    flex-wrap: wrap;
}

.battle-nav-tournament {
  margin: calc(var(--x-large-margin)) 0 var(--medium-margin);
}

.battle-nav-tournament-sub-links {
  margin: calc(var(--medium-margin)) 0 var(--small-margin);
  }
  
  .battle-nav-tournament-explainer {
    text-align: center;
    margin: calc(var(--medium-margin)) 0 var(--medium-margin);
}

.battle-nav__item {
    border: 1px solid var(--text-color);
    border-radius: 60px;
    cursor: pointer;
    padding: var(--small-margin) var(--medium-margin);
    text-align: center;
}

.battle-nav__item a.battle-nav__link {
    text-decoration: none;
    text-transform: uppercase;
}


.battle-nav__item--active a.battle-nav__link {
    color: var(--main-bg-color);
}

.battle-nav__item:hover {
    text-decoration: underline;
}

.battle-nav__link--disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.battle-nav__item--active {
    background-color: var(--text-color);
    color: var(--main-bg-color);
}


@media screen and (max-width: 600px) {
  .battle-nav {
    flex-wrap: wrap;
    margin: var(--x-large-margin) 0;
  }
.battle-nav__item {
  width: 48%;
}
// when only one round on mobile. prolly cleaner CSS fix overall
.battle-nav__item--mobile-one-round {
  width: 100%;
}
}

.battle-nav__item-top .button {
  text-transform: uppercase;
  padding: var(--small-margin) var(--medium-margin);
}
