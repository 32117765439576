.landing-page__section--tournament {
  width: 100%;

  .landing-page__section-content {
    margin: 0 auto;
  }

  .main-logo img {
    margin: 0 auto var(--large-margin);
  }

  .landing-page__h2 span {
    display: block;
  }

  .hero-section {
    margin-top: var(--x-large-margin);
    color: #000;
  }

  .hero-section .landing-page__section-content {
    position: relative;
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    padding: 0 var(--x-large-margin);

    > * {
      position: relative;
    }
  }

  .hero-section__left .landing-page__h2 {
    text-align: left;
  }

  .hero-section__left,
  .hero-section__right {
    height: 65%;
    top: 12%;
  }

  .hero-section__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
  }

  .hero-section .landing-page__section-content:before {
    content: '';
    background-color: #ACA3FF;
    border-radius: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 77%;
  }

  .landing-page__h2--amarante {
    line-height: 58px;
  }

  .grid.grid--three {
    gap: 4rem;
    margin-top: var(--large-margin);
  }

  .grid.grid--five {
    gap: 3rem;
  }

  .hero-section__right,
  .game-section__title {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
  }

  .hero-section,
  .additional-section,
  .game-section {
    margin-bottom: calc(var(--x-large-margin) * 2);
  }

  .game-section__title {
    text-align: center;
    max-width: 60%;
    margin-bottom: var(--x-large-margin);
  }

  .game-doc .grid__item .title {
    position: relative;
    padding-top: var(--small-margin);
    margin-bottom: var(--medium-margin);
  }

  .game-doc .grid__item .title:before {
    content: '';
    width: 85%;
    height: 1px;
    background-color: #FFF;
    position: absolute;
    top: -14px;
    right: 0;
  }

  .game-doc {
    display: flex;
    gap: 5rem;
    margin-bottom: calc(var(--x-large-margin) * 3);
  }

  .game-doc > .title {
    flex: 40%;
  }

  .game-doc .title,
  .game-doc ol li:before {
    font-family: Amarante, cursive;
    font-size: 32px;
  }

  .game-doc ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }
  .game-doc ol li {
    counter-increment: my-awesome-counter;
  }
  .game-doc ol li:before {
    content: counter(my-awesome-counter);
  }

  .landing-page__section--disclaimer {
    width: 100%;

    .landing-page__section-content {
      margin: 0 auto;
      gap: 0;
    }
  }
}

@media screen and (min-width: 901px) {
  .landing-page__section--tournament {
    .hero-section .landing-page__section-content img {
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .landing-page__section--tournament {
    .hero-section .landing-page__section-content {
      grid-template-columns: repeat(3, 1fr);
      padding: 0 var(--medium-margin);
    }
  }
}

@media screen and (max-width: 900px) {
  .landing-page__section--tournament {
    .game-doc .title,
    .game-doc ol li:before,
    .hero-section__right,
    .game-section__title,
    .button {
      font-size: 24px;
    }

    .landing-page__h2--amarante {
      font-size: 32px;
      line-height: 38px;
    }

    .game-doc {
      gap: 1rem;
    }

    .game-doc .grid__item .title:before {
      width: 95%;
    }
  }
}

@media screen and (max-width: 480px) {
  .landing-page__section--tournament {
    .hero-section .landing-page__section-content {
      display: flex;
      flex-direction: column;
      grid-template-columns: none;
      gap: 0;
      padding: 0 var(--medium-margin);
    }

    .hero-section .landing-page__section-content:before {
      height: 100%;
    }

    .hero-section__left {
      order: 1;
    }
    .hero-section__right {
      order: 2;
    }
    .hero-section img {
      order: 3;
    }

    .hero-section__left,
    .hero-section__right {
      height: auto;
      top: 5%;
    }
    .hero-section__left .landing-page__h2,
    .hero-section__right {
      text-align: center;
      margin-bottom: var(--large-margin);
    }

    .game-section__title {
      max-width: 100%;
    }

    .game-doc {
      flex-direction: column;
    }

    .button {
      text-align: center;
    }
  }
}

.rules-wrapper table {
  text-align: center;
  width: 100%;
}

.rules-wrapper ul {
  margin-left: 10px;
}

// Tournament champions

.champions-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.champions-header h4 {
  font-size: 120%;
}

.champions-header .tourney-card__title {
  margin-bottom: var(--large-margin);
}

.champions-header__row {
  display: flex;
  width: 100%;
  gap: var(--large-margin);
}

.champions-header__row .champions-header__section {
  flex-shrink: 0;
  margin-bottom: var(--small-margin);
  width: 50%;
}

.fallen-header__section {
  width: 100%;
}

.champion_places {
  display: flex;
  gap: var(--medium-margin);
}

.champion_place {
  border-top: 2px solid white;
  padding: var(--medium-margin) 0;
  flex: 1;
  display: flex;
  gap: var(--medium-margin);
  flex-direction: column;
}

.places-group {
  display: flex;
  gap: var(--medium-margin);
}

.landing-page__section--tournament_navigation nav {
  width: 100%;
  gap: var(--large-margin);
  display: flex;
  flex-wrap: wrap;
}

.button__secondary-label {
  display: flex;
  gap: var(--medium-margin);
}
.button__secondary-label span {
  opacity: 0.5;
}

.landing-page__section--champion-matchups .landing-page__section-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
}

.tournament_matchup {
  display: flex;
  padding: 15px;
  position: relative;
  height: 50px;
}

.tournament_matchup .warrior__image {
  width: 150px;
  height: inherit;
  max-width: none;
  margin-top: -75px;
}
.tournament_matchup .warrior__level-5 .warrior__image {
  width: 140px;
}
.tournament_matchup .warrior__level-5.warrior__details--brawler .warrior__image {
  width: 180px;
}
.tournament_matchup .warrior__details:first-child {
  left: -10px;
  position: absolute;
}

.tournament_matchup .warrior__details:last-child {
  right: -60px;
  position: absolute;
}

// Adjust positioning for characters
.tournament_matchup .warrior__details--specter {
  margin-top: -2px;
}
.tournament_matchup .warrior__details--tank,
.tournament_matchup .warrior__details--brawler,
.tournament_matchup .warrior__details--kickboxer,
.tournament_matchup .warrior__details--scholar,
.tournament_matchup .warrior__level-5.warrior__details--the-don,
.tournament_matchup .warrior__level-5.warrior__details--specter {
  margin-top: 5px;
}
.tournament_matchup .warrior__details--ninja {
  margin-top: 3px;
}
.tournament_matchup .warrior__level-5.warrior__details--scholar {
  margin-top: 8px;
}
.tournament_matchup .warrior__level-5.warrior__details--badboy,
.tournament_matchup .warrior__level-5.warrior__details--ronin  {
  margin-top: 12px;
}
.tournament_matchup .warrior__level-5.warrior__details--brawler {
  margin-top: -12px;
}

@media screen and (max-width: 1025px) {
  .champions-header__section--moonbird {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .champions-header__row {
    flex-direction: column;
  }
  .champions-header__row .champions-header__section {
    width: 100%;
  }
  .tournament-cta-wrapper,
  .champions-header__row--prizes {
    display: none !important;
  }
}

.tournament_matchup .warrior__image--fallen--scholar {
  margin-top: -100px;
}

.tournament_matchup .details--fallen--scholar {
  right: -100px;
}

.champions-header__section--moonbird {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  width: 30%;
}

.champions-header__section--moonbird img {
  margin-top: auto;
}

.tournament-cta-wrapper p.tournament-cta--text {
  font-size: 16px;
}

.tournament_matchup--owned {
  border: 1px solid var(--yellow-color);
}
