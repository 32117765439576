.marquee-w {
  position: relative;
  width: 100%;
  padding: var(--medium-margin) 0;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    background-image: linear-gradient(to right, var(--syndicate-color-water) 10%, rgba(255, 255, 255, 0) 0%);
    background-size: 7px 1px;
    background-repeat: repeat-x;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
  }

  &:before {
    background-position: top;
    top: 0;
  }
  &:after {
    background-position: bottom;
    bottom: 0;
  }
}

.marquee {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
  white-space: nowrap;
}

.marquee span {
    position: relative;
    display: inline-block;
    width: 49.2%;
    color: var(--syndicate-color-water);
    animation: marquee 30s linear infinite;

  &:before,
  &:after {
    content: attr(data-text);
    margin: 0 2rem;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
