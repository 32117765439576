.landing-page__section.landing-page__section--hero {
  margin-bottom: 0;
  overflow: hidden;
  padding: 0 32px;
  position: relative;

  .main-logo {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
  }
}

.landing-page__section.landing-page__section--hero.page--battles {
  overflow: visible;
}
// Warrior list
.landing-page__warriors {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: baseline;
  gap: 2rem;
  margin-bottom: var(--x-large-margin);
}

.landing-page__hero__warrior {
    position: relative;
    height: 120px;
}

.landing-page__hero__warrior--image {
    height: 100%;
    object-fit: contain;
}

.landing-page__hero__warrior--name {
  padding-top: 10px;
  bottom: -10px;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
  background-position: 0 0;
  background-repeat: repeat-x;
}

// Artist
.landing-page__section--artist {
  text-align: center;
}

// Team
.landing-page__section--team {
  text-align: center;
  margin: var(--x-large-margin) 0;
}


// Gameplay
// .landing-page__section--gameplay,
.landing-page__section--faq {
  .landing-page__paragraph:not(.landing-page__paragraph--nospace) {
    margin-bottom: 1rem;
  }
}

.landing-page__h2--amarante {
  font-family: "Amarante", cursive;
  font-size: 60px;
}

.landing-page__section--gameplay {
  overflow: hidden;

//   img {
//     width: 100%;
//     margin-bottom: var(--large-margin);
//   }

//   .levels {
//     margin: calc(var(--x-large-margin) * 4) 0 calc(var(--x-large-margin) * 2);
//   }

//   .video-player {
//     margin-bottom: var(--medium-margin);
//   }

//   .landing-page__h2--amarante {
//     font-size: 32px;
//     margin-bottom: var(--x-large-margin);
//   }

//   ul:not(.levels-list) {
//     margin-left: 1rem;
//     margin-bottom: 1rem;
//   }

//   .landing-page__sub-section__title {
//     margin-bottom: var(--medium-margin);
//     font-family: 'Inter', sans-serif;
//     font-size: 24px;
//   }

//   .landing-page__paragraph {
//     text-align: left;
//   }

//   .landing-page__paragraph--opacify {
//     opacity: 0.5;
//   }

//   .landing-page__sub-section__number {
//     font-family: 'Amarante', cursive;
//     font-size: 26px;
//     width: 50px;
//     height: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     margin-bottom: var(--large-margin);

//     &:before {
//       content: '';
//       border: 2px solid #FFF;
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       transform: rotate(45deg);
//       transform-origin: center;
//     }
//   }
}

// FAQ
.landing-page__section--faq {
  .landing-page__paragraph,
  .landing-page__h2 {
    text-align: left;
  }
}

.landing-page__section--faq {
  .landing-page__h2 {
    font-family: 'Amarante', cursive;
    font-size: 32px;
    width: 100%;
  }
}

// Notes
.landing-page__section--notes .landing-page__section-content {
    width: 100%;
}

// Disclaimer
.landing-page__section.landing-page__section--disclaimer {
  padding-bottom: var(--x-large-margin);
  width: 430px;
  font-size: 12px;
  opacity: 0.5;
}

// Music player
.player-container {
    z-index: 10; // appear above clouds
}

.player-button {
    align-items: flex-start;
    display: flex;
    height: 30px;
    padding: 0;
    width: 30px;
}

.player-button img {
    height: 30px;
    position: fixed;
    width: 30px;
}

.player-button:hover {
    opacity: 0.8;
}

// Mint
.landing-page__section.landing-page__section--mint {
    gap: var(--medium-margin);
    margin-top: 0;
    min-height: 47vh;
}

// Desktop only
@media screen and (min-width: 821px) {
  .main-banner {
    margin-top: var(--x-large-negative-margin);
  }

  .landing-page__warriors {
    grid-template-columns: repeat(9, 1fr);
  }

  .landing-page__section.landing-page__section--about,
  .landing-page__section.landing-page__section--artist,
  .landing-page__section.landing-page__section--team {
    margin-bottom: calc(var(--x-large-margin) * 2);
  }

  .landing-page__section--faq {
  // .landing-page__section--gameplay {
    .landing-page__h2--amarante {
      font-size: 60px;
    }
  }

  // .landing-page__section--gameplay {
  //   .landing-page__sub-section__title {
  //     font-size: 32px;
  //   }

  //   .grid {
  //     gap: 2rem;
  //   }

  //   .grid .grid__item:nth-child(2) {
  //     margin-top: 15rem;
  //   }
  //   .grid .grid__item:nth-child(3) {
  //     margin-top: 30rem;
  //   }
  // }
}

// Mobile/Ipad only
// @media screen and (max-width: 820px) {
//   .landing-page__section--gameplay {
//     .grid .grid__item {
//       margin-bottom: var(--x-large-margin);
//     }

//     .levels {
//       margin: var(--large-margin) 0;
//     }
//   }

//   .faq {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }
