// --------------------------------------------------------
// Header
// --------------------------------------------------------
.tournament-header {
  display: flex;
  flex-direction: column;
  gap: 148px;
  width: 100%;
}

.landing-page__section--tournament .main-logo {
  margin-right: auto;
}

.tournament-battles {
  flex: 0 0 auto;
  margin-bottom: var(--small-margin);
  margin-left: auto;
  text-align: right;
}

.tournament-battles img {
  margin: 0 -14px 11px auto;
}

.tournament-cta p,
.tournament-battles p {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  margin-bottom: 24px;
}

@media screen and (min-width: 821px) {
  .tournament-header {
    align-items: flex-start;
    flex-direction: row;
    gap: 48px;
  }

  .tournament-cta p,
  .tournament-battles p {
    font-size: 24px;
    line-height: 1.1667;
  }
}

.tournament-sub-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tournament-cta-button {
  font-size: 20px;
  margin: 0em 1em;
}

// --------------------------------------------------------
// Card Layout and Typography
// --------------------------------------------------------
.tourney-card {
  color: #000;
  display: grid;
  grid-template: repeat(4, auto) / 1fr 412px;
  margin: 0 auto 64px;
  max-width: 1254px;
  width: 100%;

  @media (max-width: 780px) {
    grid-template: repeat(4, auto) / repeat(2, 1fr);
  }
}

.landing-page .tourney-card {
  padding: 0 32px;
}

.tourney-card__heading,
.tourney-card__button,
.tourney-card__media,
.tourney-card__content-bottom  {
  z-index: 5;
}

.tourney-card__title {
  color: #FFF;
  font-family: 'Amarante', cursive;
  font-size: 60px;
  grid-area: 1 / 1 / 2 / -1;
  line-height: 1.1;
  z-index: 9; // less than mobile nav

  @media (max-width: 780px) {
    grid-area: 1 / 1 / 2 / -1;
  }

  @media (max-width: 540px) {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

.tourney-card__heading {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  grid-area: 2 / 1 / 3 / 2;
  padding: 32px 32px 16px;
  place-self: end start;

  @media (max-width: 780px) {
    place-self: start;
  }

  @media (max-width: 540px) {
    font-size: 24px;
  }
}

.tourney-card__button {
  grid-area: 3 / 1 / 4 / 2;
  place-self: start;

  @media (max-width: 780px) {
    grid-area: 3 / 1 / 4 / -1;
    place-self: center;
  }
}

.tourney-card__text {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  margin: 16px 32px;

  @media (max-width: 960px) {
    font-size: 24px;
  }
}

.tourney-card__button .button {
  background-color: #000;
  border-color: #000;
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  margin: 16px 32px;
}

.tourney-card__sub-heading {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.tourney-card__prize-name {
  margin-bottom: 16px;
}


// --------------------------------------------------------
// Grand Prize Image
// --------------------------------------------------------
.tourney-card__media {
  grid-area: 1 / 2 / 4 / 2;
  padding-right: 32px;
  place-self: end;
  max-width: 270px;

  @media (max-width: 780px) {
    grid-area: 1 / 2 / 3 / -1;
  }
}

.tourney-card__media--burst {
  background: no-repeat url('/img/tournament/burst.png') center bottom;
  background-size: 100% auto;
}


// --------------------------------------------------------
// Bottom column layout
// --------------------------------------------------------
.tourney-card__content-bottom {
  display: grid;
  grid-area: 4 / 1 / -1 / -1;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr) 412px;
  padding: 0 32px 32px;

  @media (max-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.tourney-card__content-bottom--alt {
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.tourney-card__content-bottom > div {
  border-top: 1px solid #000;
  font-family: 'Inter', sans-serif;
  padding-top: 32px;
}

// --------------------------------------------------------
// Card BG
// --------------------------------------------------------
.tourney-card__bg {
  border-radius: 10px;
  grid-area: 2 / 1 / -1 / -1;
  z-index: 1;
}

// --------------------------------------------------------
// Card Colors
// --------------------------------------------------------
.tourney-card--red .tourney-card__bg {
  background-color: #F32B44;
}

.tourney-card--red .tourney-card__button .button {
  color: #F32B44;
}

.tourney-card--yellow .tourney-card__bg {
  background-color: #F2C85F;
}

.tourney-card--yellow .tourney-card__button .button {
  color: #F2C85F;
}

.tourney-card--green .tourney-card__bg {
  background-color: #5FF265;
}

.tourney-card--green .tourney-card__button .button {
  color: #5FF265;
}

// --------------------------------------------------------
// Prize (2nd-8th) Image Sizing/Layout
// --------------------------------------------------------
.prize-img {
  display: inline-block;
  width: 120px;

  @media (max-width: 780px) {
    width: 96px;
  }
}

.prize-img-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.prize-img-row img {
  width: 60px;

  @media (max-width: 780px) {
    width: 48px;
  }
}

.prize-img-row--sm img {
  width: 32px;
}


// --------------------------------------------------------
// Footer
// --------------------------------------------------------
.container--landing-page-tournament .content-wrapper {
  background: url("/img/footer_hoard.jpg") no-repeat bottom;
  background-size: 100% auto;
  padding-bottom: 77.36%;
}

.container--landing-page-tournament-winner .content-wrapper {
  background: url("/img/footer.png") repeat-x 50% 100.01%, url("/img/hyper.gif") no-repeat 50% 50%, url("/img/hyper.jpg") no-repeat 50% 50%;
  background-size: auto, cover, cover;
  padding-bottom: 70%;
}

// h/t famous bri https://bricampgomez.com/blog/how-to-overlap-images-in-css/
.image-stack {
  display: grid;
  position: relative; // imperative for the overlapping to work
  grid-template-columns: repeat(12, 1fr);

  img {
    width: 100%;
    display: block;
  }
}

.image-stack__item--bottom {
  grid-column: 4 / -1;
  grid-row: 1;
}

.image-stack__item--top {
  grid-row: 1;
  grid-column: 4 / -1;
  padding-top: 0%; // slightly arbitrary, keeps proportion once resized
  z-index: 1; // tells the browser to make this image on top
}

// --------------------------------------------------------
// Starts Soon Variation
// --------------------------------------------------------
.tourney-card--start .tourney-card__title {
  text-transform: uppercase;
}

.tourney-card--start.tourney-card--red .tourney-card__title {
  color: #F32B44;
}

.winners-warrior__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
  padding: initial;
  justify-content: center;
  width: 80%;
}


.winner-warrior__item {
  width: 400px;
  margin: 5px;
  text-align: center;
  font-size: 16px;
    margin-top: 2em;
}


.subtitle--amarante {
  font-family: "Amarante", cursive;
  font-size: 20px;
}

.tournament--section {
  max-width: 100%;
}