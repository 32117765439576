.tabs {
    border: 1px solid var(--syndicate-color-spirit);
    border-radius: 99rem;
    display: flex;
    position: relative;

    &::before {
        background-color: var(--syndicate-color-spirit);
        border-radius: 99rem;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translate3d(0, 0, 0);
        transition: all 0.3s;
        width: 52%;
        z-index: 1;
    }
}

.tabs--switch {
    &::before {
        left: 100%;
        transform: translate3d(-100%, 0, 0);
    }

    .tabs__item:first-child {
        color: var(--text-color);
    }

    .tabs__item--active {
        color: var(--main-bg-color);
    }
}

.tabs__item {
    cursor: pointer;
    font-size: 15px;
    padding: 0.5rem 2rem;
    text-transform: uppercase;

    &:first-child {
        color: var(--main-bg-color);
    }
}

.tabs__item-content {
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 800px) {
  .tabs__item {
    font-size: 13px;
  }
}
