.battle-result {
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 1px dotted var(--text-color);
    margin-bottom: var(--large-margin);
    position: relative;
    position: relative;
}

.battle-result[data-id='0'] {
    background-image:
        url('/img/bg-battle.0.jpeg')
    ;
    background-position: bottom;
}


.battle-result[data-id='1'] {
    background-image:
        url('/img/bg-battle.1.jpeg'),
        linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
        linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
        linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
    ;
    background-position: bottom;
}

.battle-result[data-id='2'] {
  background-image:
      url('/img/bg-battle.2.png'),
      linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
      linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
      linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
  ;
}

.battle-result[data-id='war'] {
  background-image:
  url('/img/bg-battle.war.jpg');

  background-position: bottom;
}

// visible results
.battle-result--visible[data-id='0'] {
    background-image:
    url('/img/bg-battle.0.jpeg'),
    linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
      linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
      linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
  ;
    background-position: bottom;
}


.battle-result--visible[data-id='war'] {
  background-image:
  url('/img/bg-battle.war.jpg'),
  linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
      linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
      linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
  ;
  background-position: bottom;
}

.battle-result[data-id='fallen'] {
  background-image:
    url('/img/backgrounds/fallen.jpg'),
    linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
    linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
    linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
  ;
  background-position: bottom;
}

.battle-result[data-id='champions'] {
  background-image:
    url('/img/backgrounds/champions.jpg'),
    linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
    linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
    linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
  ;
  background-position: bottom;
}

.battle-result[data-id='peaceful'] {
  background-image:
    url('/img/backgrounds/peaceful.png'),
    linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),
    linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%),
    linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)
  ;
  background-position: bottom;
}

.battle-result[data-id='valentines'] {
  background-image:
    url('/img/bi-weekly/background/valentines-background.png'),
      linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
      linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
      background-position: bottom;
    }

.battle-result[data-id='kita'] {
      background-image:
        url('/img/bi-weekly/background/kita-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}
.battle-result[data-id='ides'] {
  background-image:
    url('/img/bi-weekly/background/ides-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}
.battle-result[data-id='taxes'] {
  background-image:
    url('/img/bi-weekly/background/taxes-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}
.battle-result[data-id='hades'] {
  background-image:
    url('/img/bi-weekly/background/hades-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}

.battle-result[data-id='labor'] {
  background-image:
    url('/img/bi-weekly/background/labor-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}

.battle-result[data-id='pumpkin'] {
  background-image:
    url('/img/bi-weekly/background/pumpkin-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}

.battle-result[data-id='loompa'] {
  background-image:
    url('/img/bi-weekly/background/loompa-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}

.battle-result[data-id='santa'] {
  background-image:
    url('/img/bi-weekly/background/santa-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}

.battle-result[data-id='dragon'] {
  background-image:
    url('/img/bi-weekly/background/dragon-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}

.battle-result[data-id='lily'] {
  background-image:
    url('/img/bi-weekly/background/lily-background.png'),
    linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(145deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%),
    linear-gradient(225deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 30%);
  background-position: bottom;
}


.battle-result__vs {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.warrior__attack__confirm__button.warrior__attack__confirm__button--reveal.primary {
    background-image: url(/img/btn.wide-active.svg);
    bottom: 20px;
    color: var(--main-bg-color);
    position: absolute;
}

.warrior__attack__confirm__button.warrior__attack__confirm__button--reveal.primary:hover {
    background-image: url(/img/btn.wide.svg);
    color: var(--text-color);
}

.battle-result__body {
    display: flex;
    height: 460px;
    justify-content: space-between;
    padding: var(--medium-margin);
}

.battle-result .warrior__image {
    bottom: 0;
    right: 100px;
}

.battle-result .warrior__image--west {
    right: 100px;
}

.battle-result__revealer {
    height: 100%;
    left: 0;
    mix-blend-mode: screen;
    object-fit: cover;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
    z-index: 100;
}

.battle-result__revealer--finished {
    opacity: 0;
}

// Result revealed
.battle-result--visible .warrior__image--loser,
.battle-result--visible .battle-result__vs {
    display: none !important;
}

// hide Level during reveal
.battle-result--not-visible .warrior__details--score {
  display: none;
}

.battle-result--visible .warrior__details__stars {
    display: block;
}

.battle-result .battle-chronicle {
  display: none;
}
/* stylelint-disable selector-max-class */

.battle-result-status__headline.battle-result-status__headline--mobile {
  display: none;
  max-width: 60%;
  margin: 0 auto;
}

.warrior__image--active {
  display: none;
}

.warrior__image--not-active {
  display: block;
}

.warrior__active--true .warrior__image--active {
  display: block;
}

.warrior__active--true .warrior__image--not-active {
  display: none;
}


// DESKTOP ONLY
@media screen and (min-width: 901px) {
  .battle-result .warrior__image--east {
    left: 100px;
  }

  // position winner in center on dektop
  .battle-result--visible {
    .warrior--east:not(.false) {
      .warrior__image {
        left: 55%;
        transform: scaleX(-1) translate3d(55%, 0, 0);
      }
    }
    .warrior__image {
      right: inherit;
      left: 40%;
      transform: translate3d(-40%, 0, 0);
    }
  }
}

@media screen and (max-width: 900px) {
  .battle-result.battle-result--visible {
    .warrior:not(.false) {
      .warrior__image {
        width: inherit;
        height: inherit;
        left: -5%;
        right: inherit;
        transform: translate3d(-5%, 0, 0) scaleX(-1);
      }
    }

    .warrior__details__stars {
      right: -15%;
      bottom: 0;
    }
  }
}

@media screen and (max-width: 750px) {
    .battle-result-status__headline.battle-result-status__headline--desktop {
      display: none;
    }

    .battle-result-status__headline.battle-result-status__headline--mobile {
      display: block;
    }


    .warrior__attack__confirm__button.warrior__attack__confirm__button--reveal.primary {
        bottom: 40px;
    }

    .warrior__attack__image {
      width: 40px;
    }
}

/* stylelint-enable selector-max-class */
.battle-result-status {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.battle-result-status__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

.battle-result-status__text {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--small-margin);
    text-transform: uppercase;
}

.battle-result-status__headline {
    max-width: 100%;
}

.battle-result__attacks {
    align-items: center;
    bottom: var(--medium-margin);
    display: flex;
    flex-direction: row;
    gap: var(--small-margin);
    position: absolute;
}

.attack-status__attack {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 var(--small-margin);
}

.attack-status {
  text-transform: uppercase;
}

.attack-choice {
  margin-top: 10px;
  text-transform: capitalize;
}

.battle-result-image__container {
  margin-bottom: 105px;
}
