// --------------------------------------------------------
// Naming Form Styles
// --------------------------------------------------------
.form--naming {
  font-family: 'Inter', sans-serif;
}

.form--naming input[type='text'] {
  background-color: #000;
  border: 1px solid #FFF;
  color: #FFF;
  font-size: 32px;
  outline: 0;
  padding: 8px 30px;
  text-align: center;
  width: 100%;
}

.form--naming button[type='submit'] {
  font-size: 32px;
  padding: 0.25em 1.5em;
}

.form--naming .radio-button input[type='radio'] + label {
  font-size: 15px;
}

.form--signup input[type='email'] {
  background-color: #000;
  border: 1px solid #FFF;
  color: #FFF;
  font-size: 28px;
  outline: 0;
  padding: 8px 30px;
  text-align: left;
  width: 100%;
}

.form--signup label {
  font-size: 16px;
  margin-bottom: 20px;
}

.warrior-name {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}

.warrior-name__desc {
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
  text-align: center;
}

.warrior-name__text,
.warrior-name__media {
  grid-area: 1 / 1 / -1 / -1;
}

.warrior-name__text {
  font-family: 'Amarante', cursive;
  font-size: 62px;
  line-height: 1.1;
  max-width: 480px;
  text-align: center;
  z-index: 5;
}

.warrior-name__media {
  display: grid;
  grid-template: auto / auto;
  height: 375px;
  overflow: hidden;
  place-items: center;
  pointer-events: none;
  user-select: none;
  width: 375px;
  z-index: 1;
}

.warrior-name__media img {
  height: auto;
  position: absolute;
  max-width: 704px;
  width: 704px;
}

.warrior-name-success {
  background: url('/img/burst.png') no-repeat center center;
  background-size: cover;
  display: grid;
  flex: 1;
  place-items: center;
  position: relative;
  width: 100%;
}

.randomize-button {
  margin-left: 20px;
  transition: opacity 0.2s ease-in-out;
}

.randomize-button:hover {
  background-color: transparent;
  opacity: 0.8;


}
.randomize-button img {
  height: 50px;
  width: 25px;
  fill: white;
}

.content-wrapper--naming {
  min-height: 88vh;
}

.name-warrior--wrapper {
  border: 1.42px solid #9e9999;
  border-radius: 10px;
  padding: 50px;
}

.name-error {
  font-size: 32px;
  color: var(--error-bg-color);
}

.dice--wrapper {
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  margin-left: 30px;
  height: 70px;
  width: 70px;
  transition: all 0.3s;
}

.dice--wrapper:hover {
  opacity: 0.8;
  background-color: white;
}

.dice--wrapper svg {
  height: 50px;
  width: 50px;
}

.dice--wrapper:hover path {
  fill: black;
}

.name-wallet-connect {
  margin-top: var(--medium-margin); 
}

.warrior-name--dead {
  margin-top: -200px;
}

img.warrior-name--dead {
  width: 500px;
}

.naming-title-spacing {
  margin-bottom: var(--medium-margin); ;
}

.radio-button input:not(:checked):focus + label,
.radio-button input:not(:checked):hover + label {
    background-color: black !important;
    color: white;
}
