.levels {
  width: 100%;
  position: relative;

  .levels-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    margin: var(--large-margin) 0 var(--x-large-margin);
    width: 100%;

    .levels__item {
      display: inline-flex;
      flex-direction: column;
      gap: 1.5rem;
      border-bottom: 1px solid #FFF;
      position: relative;

      @for $i from 2 to 6 {
        &:nth-child(#{$i}) {
          top: -30px * $i;
        }
      }
    }

    .name {
      opacity: 0.5;
    }

    .nbr {
      font-size: 32px;
    }
  }
}


@media screen and (max-width: 620px) {
  .levels {
    .levels-list {
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      margin: var(--small-margin) 0;

      .levels__item {
        gap: 0;

        @for $i from 2 to 6 {
          &:nth-child(#{$i}) {
            top: 0;
          }
        }

        .name {
          font-size: 10px;
        }
        .nbr {
          font-size: 16px;
        }
      }
    }
  }
}
