.container--landing-page-warriors {
  .landing-page__section--header,
  .landing-page__section--body {
    margin-bottom: calc(var(--x-large-margin) * 2);
    width: var(--max-width-container);
    max-width: 100%;
    overflow-x: auto;
  }

  .landing-page__section--header .main-logo img {
    margin-left: auto;
    margin-right: auto;
  }

  .grid__item,
  .label {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='1' viewBox='0 0 12 1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23FFF'/%3E%3Crect x='6' width='1' height='1' fill='%23FFF'/%3E%3C/svg%3E%0A");
    background-repeat: repeat-x;
    background-position: 0 100%;
  }

  .grid.grid--seven {
    grid-template-columns: 220px repeat(6, minmax(80px, 1fr));
    gap: 1.5rem;
  }

  .column:not(.column--first) {
    .grid__item {
      display: flex;
      align-items: flex-end;
    }
  }

  .column--first .grid__item {
    padding-top: 1rem;
  }

  .column--first {
    margin-top: 37px;
  }

  .grid__item {
    height: 127px;
  }

  .name {
    font-family: Amarante, cursive;
    font-size: 32px;
  }

  .label {
    text-align: right;
    padding-bottom: 1rem;
  }
}


@media screen and (max-width: 900px) {
  .container--landing-page-warriors {
    .grid.grid--seven {
      grid-template-columns: 130px repeat(6, minmax(80px, 1fr));
    }

    .grid__item {
      height: 100px;
    }

    .name {
      font-size: 24px;
    }
  }
}
