// small classes shared state wide

.underline {
  text-decoration: underline;
}

// similar to main-nav-nav__item
.link {
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.2s ease-in-out;
}

.link:hover {
  opacity: 0.8;
}

.bold {
  font-weight: bold;
}

.wrapper-text {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--small-margin);
}


.content-wrapper--full {
  min-height: 88vh;
}

// when not a lot of content, stick footer to bottom.
// note: probably cleaner way to do this overall
.content-wrapper--small {
  height: 100%;
  overflow: auto;
}
.content-wrapper--hidden {
  overflow-x: hidden
}
.text--error {
  color: var(--error-bg-color);
}
