// HERO
.profile__hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(80vh - 97px - var(--x-large-margin));
  position: relative;
}

.profile__hero-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: calc((97px + var(--x-large-margin)) * -1);
  left: 0;
  width: 100%;
  height: 80vh;
}

.profile-container {
  max-width: var(--max-width-container);
  padding-inline: var(--large-margin);
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.profile__hero-title {
  font-family: "Amarante", cursive;
  font-size: 80px;
  line-height: 100px;
  letter-spacing: -2%;
}

@media (max-width: 960px) {
  .profile__hero-title {
    font-size: 50px;
  }
}

// METAS
.profile__hero-metas {
  display: flex;
  justify-content: space-between;
}

.profile__metas-title {
  font-weight: 700;
  font-size: 24px;
}

.medals__list,
.trophies__list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.medals .profile__metas-title,
.trophies .profile__metas-title {
  margin-bottom: 16px;
}


.medals .profile__metas-title {
  text-align: right;
}

.medals__list-item,
.trophies__list-item {
  width: 100px;
  height: 100px;
}

// STATS
.profileStats {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
}

.profileStats__left,
.profileStats__right {
  display: flex;
  gap: 24px;
}

@media (max-width: 960px) {
  .profileStats__left,
  .profileStats__right {
    width: 100%;
    justify-content: space-between;
  }
}

.profileStats__item {
  display: inline-flex;
  flex-direction: column;
  border-top: 1px solid #FFF;
  width: 187px;
}

.profileStats__level .profileStats__value {
  display: inline-flex;
  gap: 8px;
}

.profileStats__label {
  opacity: 0.5;
  margin-top: 16px;
}

.profileStats__value {
  color: #FFF;
  font-size: 24px;
}

// KILLS
.kills {
  width: 100%;
}

.kills__list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.kills__list-item {
  width: 45px;
  height: 45px;
}

// BATTLES
.battlesRecord {
  width: 100%;
}

.battlesRecord .profile__metas-title {
  margin-bottom: 32px;
}

.battlesRecord__item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 24px;
  margin-bottom: 32px;
  min-height: 450px;
}

.battlesRecord__opponent {
  align-items: center;
  display: flex;
  gap: 8px;
}

.battlesRecord__opponent-img {
  width: 31px;
  height: 31px;
}

.battlesRecord__attack--opponent-img,
.battlesRecord__attack-img {
  width: 22px;
  height: 22px;
}

.battlesRecord__result--victory {
  color: var(--success-bg-color);
}

.battlesRecord__result--defeat {
  color: var(--red-color);
}
