.container--landing-page-choice {
  .intro {
    max-width: var(--max-width-container);
    gap: 10rem;

    .landing-page__h2--amarante {
      font-size: 32px;
    }
  }

  .landing-page__paragraph {
    text-align: left;
    max-width: 400px;
  }

  .infos {
    gap: 0;
  }

  .infos .grid__item {
    padding: var(--small-margin) 0;
  }

  .infos .grid__item,
  .actions {
    border-bottom: 1px solid rgba(#FFF, 0.3);
  }

  .infos .title {
    opacity: 0.5;
  }

  .img-wrapper {
    border-radius: 10px;
    overflow: hidden;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: var(--small-margin) var(--large-margin);
  }

  .grid.grid--four {
    margin-bottom: var(--large-margin);

    > .grid__item {
      margin-bottom: var(--medium-margin);
    }
  }

  .checkbox-button {
    flex: 1;
  }

  .checkbox-button input[type=checkbox] + label {
    justify-content: center;
    align-items: baseline;
    font-size: 14px;
  }

  .checkbox-button input[type=checkbox]:checked + label:before {
    content: '✔️';
    margin-right: 0.5rem;
    font-size: 10px;
  }
}


@media screen and (max-width: 800px) {
  .container--landing-page-choice {
    .intro {
      gap: 2rem;

      .landing-page__h2--amarante {
        font-size: 24px;
      }
    }

    .landing-page__h2--amarante {
      font-size: 32px;
    }

    .grid__item {
      margin-bottom: var(--large-margin);
    }
  }
}
