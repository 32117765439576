.ReactModal__Overlay {
    z-index: 20;
}

.modal__header {
  margin-right: 4rem;
  align-self: end;
}

.modal__close {
  color: white;
  font-size: 3rem;
}

.modal__close:hover,
.modal__close:focus {
  background-color: transparent;
  color: #fc0;
  cursor: pointer;
}