.hd-keyline {
  align-items: center;
  display: grid;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto 1fr;
  margin-bottom: 24px;
  max-width: 656px;
  width: 100%;
}

.hd-keyline::before,
.hd-keyline::after {
  background: #FFF;
  content: '';
  height: 1px;
  width: 100%;
}

.hd-keyline:first-of-type {
  margin-top: 80px;
}

.revival-headline {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  margin-bottom: 40px;
}


.grid-revive-warriors {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 120px;
  text-align: center;
}

.grid-dub-warriors {
  display: grid;
  grid-gap: 1rem 5rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 120px;
  text-align: center;
}

@media screen and (max-width: 750px) {
  .grid-dub-warriors {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-revive-warriors > .grid__item,
.grid-potions > .grid__item .potion-media {
  transition: all 0.2s ease-in-out;
}

.grid-revive-warriors > .grid__item {
  display: grid;
  color: #FFF;
  grid-column: 1 / -1;
  place-items: center;
  padding: 1rem;

  @media (min-width: 481px) {
    grid-column: auto / span 6;
  }

  @media (min-width: 620px) {
    grid-column: auto / span 3;
  }

  @media (min-width: 1172px) {
    grid-column: auto / span 2;
  }
}

.grid-revive-warriors > .grid__item:hover,
.grid-revive-warriors > .grid__item.is-selected {
  background-color: #fff;
  border-radius: 6px;
  color: #000;
}

.grid-revive-warriors > .grid__item:disabled {
  background-color: inherit;
  color: #FFF;
  cursor: not-allowed;
}

.grid-potions {
  margin-bottom: 48px;
  text-align: center;

  @media (min-width: 620px) {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 180px);
  }
}

.grid-potions > .grid__item {
  margin-bottom: 2rem;
  padding: 0;

  @media (min-width: 620px) {
    margin-bottom: 0;
  }
}

.grid-potions__text {
  color: #FFF;
  opacity: 0.5;
}

.grid-potions__explainer {
  // margin: 1em;
}

.potion-media {
  border-radius: 50%;
  height: 142px;
  display: grid;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  place-items: center;
  width: 142px;
}

.potion-media img {
  height: auto;
  width: 80px;
}

.potion-img__explainer {
  height: auto;
  width: 40px;
  display: inline;
  vertical-align: middle;
}

.grid__item:hover .potion-media,
.is-selected .potion-media {
  background-color: #FFF;
}

.grid__item--static {
  pointer-events: none;
}

.revival-button {
  margin-bottom: 32px;
}

.revival-note {
  margin-bottom: 72px;
  opacity: 0.5;
}

// .revival-warning {
//   // opacity: 0.5;
// }

.revival__load-more {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: var(--large-margin) 0;
}


// --------------------------------------------------------
// It's alive section
// --------------------------------------------------------
.its-alive {
  display: grid;
  grid-gap: 1rem;
  grid-template: repeat(2, auto) / 1fr auto 1fr;
  margin-bottom: 80px;
  place-items: center;
}

.its-text,
.alive-text {
  display: none;
}

.its-alive-text {
  margin-bottom: 48px;
}

@media (min-width: 800px) {
  .its-alive-text {
    display: none;
  }

  .its-text,
  .alive-text {
    display: block;
  }

  .its-text {
    grid-area: 1 / 1 / 2 / 2;
    place-self: center end;
  }

  .alive-text {
    grid-area: 1 / 3 / 2 / -1;
    place-self: center start;
  }
  .its-text img {
    height: 88px;
    width: 138px;
  }

  .alive-text img {
    height: 94px;
    width: 271px;
  }
}

.warrior-alive {
  grid-area: 1 / 2 / -1 / 3;
  max-width: 600px;
}

.warrior-alive img {
  height: auto;
  max-width: 200px;

  @media (min-width: 620px) {
    max-width: 600px;
  }
}

.empty-potion {
  grid-area: 2 / 1 / -1 / 2;
  place-self: end end;
}

.empty-potion img {
  height: auto;
  width: 40px;
}

.is-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
