
// reset
// ---------------------------------------------------------
//
// Adapted from https://github.com/hankchizljaw/modern-css-reset
//
// ---------------------------------------------------------

@viewport {
    width: device-width;
}

// --------------------------------------------------------
//
// Box sizing rules
//
// --------------------------------------------------------
*,
*::before,
*::after {
    box-sizing: border-box;
}

// --------------------------------------------------------
//
// Remove default padding
//
// --------------------------------------------------------
ul,
ol {
    padding: 0;
}

// --------------------------------------------------------
//
// Remove default margin
//
// --------------------------------------------------------
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

// --------------------------------------------------------
//
// Set core body defaults
//
// --------------------------------------------------------
html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto Mono', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    min-height: 100vh;
    text-rendering: optimizeSpeed;
}

// --------------------------------------------------------
//
// Remove list styles on ul, ol elements with a class
// attribute
//
// --------------------------------------------------------
ul[class],
ol[class] {
    list-style: none;
}

// --------------------------------------------------------
//
//  Removing the 350ms click delay in iOS
//
// --------------------------------------------------------
a,
button,
input,
select,
textarea,
label,
summary {
    touch-action: manipulation;
}

// --------------------------------------------------------
//
// Removes inherited underlines
//
// --------------------------------------------------------
a {
    text-decoration: none;
}

// --------------------------------------------------------
//
// A elements that don't have a class get default styles
//
// --------------------------------------------------------
a:not([class]) {
    text-decoration-skip-ink: auto;
}

// --------------------------------------------------------
//
// Make images easier to work with
//
// --------------------------------------------------------
img {
    display: block;
    max-width: 100%;
}

// --------------------------------------------------------
//
// Inherit fonts for inputs and buttons and headings
//
// --------------------------------------------------------
input,
button,
textarea,
select,
h1,
h2,
h3,
h4,
h5,
h6 {
    font: inherit;
}

// --------------------------------------------------------
//
// Removes inherited border and background
//
// --------------------------------------------------------
button {
    background: transparent;
    border: none;
    cursor: pointer;
}

// --------------------------------------------------------
//
// Remove all animations and transitions for people that
// prefer not to see them
//
// --------------------------------------------------------
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
        transition-duration: 0.01ms !important;
    }
}
