.mint-status {
    background-color: #000;
    border: 2px solid #FFF;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: var(--medium-margin);
    margin: 0 auto;
    max-width: 100%;
    padding: var(--large-margin);
    width: var(--narrow-component-width);
}

.mint-status--error {
    border-color: var(--error-bg-color);
    color: var(--error-bg-color);
}

.mint-status--success {
    border-color: var(--success-bg-color);
    color: var(--success-bg-color);
}

.mint-status--in-progress {
    border-color: var(--secondary-text-color);
    color: var(--secondary-text-color);
}

.mint-form {
    background-color: #000;
    border: 2px solid #FFF;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: var(--medium-margin);
    margin: 0 auto;
    max-width: 100%;
    padding: var(--large-margin);
    width: var(--narrow-component-width);
}

.mint-form__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mint-form form {
    display: flex;
    gap: var(--small-margin);
}

.mint-form p.landing-page__paragraph {
    text-align: left;
}

.mint-form input {
    background-color: #000;
    border: 2px solid #FFF;
    color: var(--text-color);
    padding: 0 var(--small-margin);
    text-align: center;
    width: 100px;
}

.mint-form button {
    background: url('/img/btn.empty.svg') no-repeat;
    background-size: contain;
    color: var(--text-color);
    cursor: pointer;
    font-size: 80%;
    height: 32px;
    overflow: hidden;
    padding: 0 var(--small-margin);
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 136px;
}

button:disabled {
    cursor: auto;
}

.button:disabled,
.mint-form button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}


