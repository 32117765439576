
.landing-page__section {
    display: flex;
    gap: var(--large-margin);
    margin-bottom: var(--x-large-margin);
    width: 100%;

    &--narrow {
      .landing-page__section-content {
        width: var(--narrow-component-width);
        margin: 0 auto;
      }
    }
}

@media screen and (max-width: 900px) {
    .landing-page__section {
        width: 100%;

        &--narrow {
          .landing-page__section-content {
            width: 100%;
          }
        }
    }
}

.landing-page__section-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--large-margin);
    width: var(--max-width-container);
    margin: 0 auto;
}

.landing-discord--content {
  width: 100%;
  align-items: center !important;
}

.landing-page__section--hero .landing-page__section-content {
    align-items: initial;
}

.landing-page__section__image {
    align-items: baseline;
    display: flex;
    width: 240px;
}

.landing-page__section__image--right {
    align-items: flex-end;
}

.landing-page__section__image img {
    height: auto;
    object-fit: contain;
    transition: all 0.2s ease-out;
    width: 100%;
}

@media screen and (max-width: 620px) {
    .landing-page__section__image {
        display: none;
    }

    .landing-page__section__image img {
        opacity: 0;
        transform: translateX(-100%);
    }

    .landing-page__section__image--right img {
        opacity: 0;
        transform: translateX(100%);
    }
}

.landing-page__sub-section--evolution img {
    height: 250px;
    object-fit: contain;
}

.landing-page__sub-section--evolution .landing-page__h4 {
    text-align: center;
}

.landing-page__paragraph a,
.landing-page__h2 a {
  text-decoration: underline;
}

.landing-page__paragraph {
    text-align: center;
}

.landing-page__sub-section .landing-page__paragraph {
    text-align: left;
}

.landing-page__list-item {
    padding-left: 16px;
    position: relative;
}

.landing-page__list-item::after {
    background: var(--text-color);
    content: '';
    display: block;
    height: 6px;
    left: 0;
    position: absolute;
    top: 7px;
    transform: rotate(45deg);
    width: 6px;
}

.landing-page__section-document-wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--large-margin);
    justify-content: space-evenly;
}

@media screen and (max-width: 900px) {
    .landing-page__section-document-wrapper {
        flex-direction: column;
    }
}

.landing-page__document {
    border: 1px solid var(--text-color);
    border-radius: 0 10px 10px;
    margin-top: var(--x-large-margin);
    padding: var(--large-margin);
    position: relative;
}

.landing-page__document h3 {
    align-items: center;
    background: url('/img/folder_white.svg') no-repeat top left;
    display: flex;
    font-size: 80%;
    height: 34px;
    justify-content: center;
    left: -1px;
    padding: 10px 0;
    position: absolute;
    text-indent: -12px;
    text-transform: uppercase;
    top: -34px;
    width: 170px;
    z-index: 10;
}

.landing-page__document__content {
    display: flex;
    flex-direction: column;
    gap: var(--large-margin);
    margin-top: -1px;
    max-height: 600px;
    max-width: 500px;
    overflow-y: auto;
    padding: var(--large-margin);
}

.landing-page__document__content::-webkit-scrollbar {
    width: 10px;
}

.landing-page__document__content::-webkit-scrollbar-track {
    background: rgba(196, 196, 196, 0.3);
}

.landing-page__document__content::-webkit-scrollbar-thumb {
    background: var(--text-color);
}

.landing-page__document__content .landing-page__paragraph {
    text-align: left;
}

@media screen and (max-width: 900px) {
    .landing-page__document {
        padding: var(--medium-margin);
    }

    .landing-page__document__content {
        max-height: initial;
        padding: var(--medium-margin);
    }
}


