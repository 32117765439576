.button {
  background-color: transparent;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  align-items: center;
  border-radius: 99em;
  padding: 0.5em 2em;
  transition: all 0.3s;

  &:hover, &.active {
    background-color: #FFF;
    color: #000;
  }
}

.button-row {
  display: flex;
  justify-content: center;
}



.button--pixel {
  align-items: center;
  background: transparent;
  border: 2px solid #FFF;
  color: #FFF;
  display: inline-flex;
  line-height: 1;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
}

.button--pixel::before,
.button--pixel::after {
  content: '';
  flex-shrink: 0;
  height: 32px;
  margin-bottom: -2px;
  margin-top: -2px;
  width: 8px;
}

.button--pixel::before {
  background: #000 url('/img/btn.edge.left.svg') no-repeat 0 0;
  margin-left: -7px;
  margin-right: 1em;
}

.button--pixel::after {
  background: #000 url('/img/btn.edge.right.svg') no-repeat 0 0;
  margin-left: 1em;
  margin-right: -7px;
}

.button--pixel--red {
  border-color: #F32B44;
  color: #F32B44;
}

.button--pixel--red::before {
  background: #000 url('/img/btn.edge.left.red.svg') no-repeat 0 0;
}

.button--pixel--red::after {
  background: #000 url('/img/btn.edge.right.red.svg') no-repeat 0 0;
}

.button--avatar__centered {
  margin-left: 39px;
}

.button--pixel--active {
  background-color: #FFF;
  color: #000;
}