.battle-matchup {
    border-top: 1px solid var(--text-color);
    position: relative;
    background-color: var(--main-bg-color);
}

.battle-matchup__body {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 40px;
}

.battle-matchup__body--loading {
  padding: 90px 40px;
}

.battle-matchup__body > div {
    flex: auto;
}

// DESKTOP ONLY
@media screen and (min-width: 901px) {
  .battle-matchup__body .warrior__image {
    bottom: -80px;
  }
  
  .battle-matchup__body .warrior__image--east {
    left: 35px;
    right: auto;
    transform: scaleX(-1);
  }
  
  .battle-matchup__body .warrior__image--west {
    right: 45px;
  }
}


@media screen and (max-width: 750px) {
    .battle-matchup__body {
        align-items: center;
        flex-direction: column;
        gap: var(--medium-margin);
    }

    .warrior {
        width: 100%;
    }

    .battle-matchup__body .warrior__image--east {
        left: 25%;
    }
    
    .battle-matchup__body .warrior__image--west {
      right: 25%;
      bottom: 70px;
    }

    .battle-matchup__body .warrior__image {
      max-width: 200px;
      max-height: 200px;
    }

    // .warrior__image--west {
    //   right: 25%;
    //   bottom: 70px;
    // }
    .battle-matchup__body > div {
      flex-direction: column;
    }
}

.battle-matchups--loading {
    align-items: center;
    display: flex;
    justify-content: center;
}

.warrior__attack {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--medium-margin);
    position: relative;
}

.battle-choose-attack {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--medium-margin);
}

.battle-choose-attack h4 {
    font-size: 110%;
}

.battle-choose-attack .attack-options {
    align-items: center;
    color: var(--text-color);
    display: flex;
    gap: var(--small-margin);
}

@media screen and (max-width: 750px) {
    .battle-choose-attack {
        width: 100%;
    }

    .warrior__attack {
        width: 100%;
    }

    .battle-choose-attack .attack-options {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .battle-chronicle--desktop.battle-chronicle {
      display: none;
    }
}

.attack-option {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--small-margin);
}

.attack-options .attack-label {
    color: var(--text-color);
    font-size: 90%;
}

.attack-option__icon {
    // border: 1px solid var(--text-color);
    // border-radius: 50%;
    // height: 50px;
    // overflow: hidden;
    max-width: 30px;
}

.attack-option__icon-img {
    height: 100%;
    object-fit: contain;
    // opacity: 0;
    // transform: scale(3) translateY(-15%) translateX(-15%);
    transition: all 0.3s ease-in-out;
    width: 100%;
    opacity: 1;
    transform: scale(1.5) translateY(0) translateX(0);
}

.attack-option__icon:hover {
    // outline: 2px solid var(--text-color);
}

// .attack-option__icon:hover .attack-option__icon-img {
//     opacity: 1;
//     transform: scale(1.5) translateY(0) translateX(0);
// }

.attack-option__beats {
    align-items: center;
    bottom: 0;
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 20;
}

.attack-option__beats-label {
    background: var(--main-bg-color);
    color: var(--text-color);
    font-size: 90%;
}

.attack-options--metal-active .attack-option__beats-timber,
.attack-options--metal-active .attack-option__beats-earth {
    display: flex;
}

.attack-options--water-active .attack-option__beats-fire,
.attack-options--water-active .attack-option__beats-metal {
    display: flex;
}

.attack-options--timber-active .attack-option__beats-water,
.attack-options--timber-active .attack-option__beats-earth {
    display: flex;
}

.attack-options--earth-active .attack-option__beats-water,
.attack-options--earth-active .attack-option__beats-fire {
    display: flex;
}

.attack-options--fire-active .attack-option__beats-timber,
.attack-options--fire-active .attack-option__beats-metal {
    display: flex;
}

.attack-option__bonus {
    color: var(--text-color);
    opacity: 0;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.attack-option:hover .attack-option__bonus {
    opacity: 1;
}

.attack-option:hover .attack-option__bonus[data-id='0'] {
    opacity: 0;
}

// Attack configm
.warrior__attack__confirm {
    align-items: center;
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    gap: var(--small-margin);
    height: 100%;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: scale(0.5);
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.warrior__attack__confirm--active {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
}

.warrior__attack__confirm p {
    font-size: 90%;
}

.warrior__attack__confirm__button {
    color: var(--text-color);
    cursor: pointer;
    text-decoration: underline;
}

.warrior__attack__confirm__button.primary {
    align-items: center;
    background: url('/img/btn.wide.svg') no-repeat;
    display: flex;
    height: 29px;
    justify-content: center;
    padding: 5px 15px;
    text-decoration: none;
    width: 256px;
}

.warrior__attack__confirm__button.primary:hover {
    background: url('/img/btn.wide-active.svg') no-repeat;
    color: var(--main-bg-color);
}

.warrior__attack__confirm__button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.attack-name {
    text-transform: capitalize;
}

.battle-matchup__revleal-result {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--small-margin);
    justify-content: center;
}

.battle-matchup__revleal-result button {
    text-transform: uppercase;
}

.battle-matchup__bye {
  max-width: 400px;
}

.battle-matchup__share {
// .battle-result__share {
    display: none;
}

.battle-result__share {
  display: flex;
  justify-content: center;
  margin-bottom: var(--large-margin);
}

.battle-matchups__load-more {
    align-items: center;
    border-top: 1px solid var(--text-color);
    display: flex;
    justify-content: center;
    padding: var(--large-margin) 0;
}

/* Battle Rules Button */

.battle-rules-button__wrapper {
  position: fixed;
  bottom: 15px;
  z-index: 10;
  max-width: 900px;
  width: 100%;
  pointer-events: none;
}

.battle-batch-button__wrapper {
  position: static;
  pointer-events: auto;
}
.battle-rules-button {
  align-items: center;
  color: var(--text-color);
  display: flex;
  border: 1px solid var(--text-color);
  cursor: pointer;
  gap: 10px;
  font-size: 120%;
  padding: 15px;
  background-color: var(--main-bg-color);
  pointer-events: auto;
}

.battle-rules-button img {
  width: 20px;
}

.battle-rules-button--expanded img {
  transform: rotate(180deg);
}

.battle-rules__rules {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.battle-rules__rules--expanded {
  height: 290px;
}

.batch-rules__rules--expanded {
  height: 218px;
}
@media screen and (max-width: 750px) {
  .battle-rules-button__wrapper {
    max-width: calc(100% - 30px);
  }
    .batch-batch-button__wrapper {
      max-width: 100%;
    }
  .battle-rules__rules--expanded {
    height: 100%;
  }
}

.battle-legend-caption {
  caption-side: bottom;
  text-align: left;
  padding-left: var(--small-margin);
  padding-right: var(--small-margin);
  padding-top: var(--small-margin);
  padding-bottom: 9px;
  // margin-bottom: var(--small-margin);
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  background-color: var(--main-bg-color);
}
.battle-attack-caption {
  caption-side: top;
  text-align: left;
  padding-left: var(--small-margin);
  padding-right: var(--small-margin);
  padding-top: var(--small-margin);
  padding-bottom: 9px;
  // margin-bottom: var(--small-margin);
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  background-color: var(--main-bg-color);
}
// Attack legend
.attack-legend {
  background: var(--main-bg-color);
  padding: var(--medium-margin);
  border: 1px solid white;
  border-bottom: 0px;
  width: 100%;
  border-collapse: collapse;
  max-width: calc(100% - 30px);
}


.attack-legend th {
    border-bottom: 1px solid white;
    padding: var(--small-margin);
    text-align: left;
}

.attack-legend td {
    padding: var(--small-margin);
}

.attack-legend--visible {
    display: table;
}

// Chronicle

.battle-chronicle {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--medium-margin);
}

.battle-chronicle__entry {
    display: flex;
    gap: var(--small-margin);
}

.battle-chronicle__attack {
    align-items: center;
    display: flex;
    gap: var(--small-margin);
}

.battle-chronicle__attack img {
  height: 20px;
  width: 20px;
  margin-bottom: -2px;
}

.warrior .battle-chronicle__entry {
  display: none;
  margin-top: 40px;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .warrior .battle-chronicle__entry {
    display: flex;
  }

}
// quesiton mark
.battle-matchup--life-death .warrior__image--west {
  width: 150px;
  height: 150px;
  bottom: 40px;
  right: 90px;
}

// Battle Record
// Hide on mobile
@media screen and (max-width: 750px) {
  .battle-record__footer.battle-record--mobile {
    display: none;
  }
}

.battle-record__wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.battle-record__wrapper--active {
  max-height: 100%;
  flex-direction: column;
}

.battle-record {
  border-collapse: collapse;
  width: 100%;
}
.battle-record th {
  font-weight: 600;
  text-align: left;
}
.battle-record td,
.battle-record th {
  padding: var(--small-margin) var(--medium-margin);
  border: 1px solid white;
  text-align: center;
}
.battle-record td.battle-record__result,
.battle-record th.battle-record__result,
.battle-legend-caption,
.battle-record td.battle-record__opponent-syndicate,
.battle-record th.battle-record__opponent-syndicate {
  text-align: left;
}
.battle-record__icon {
  display: inline;
  vertical-align: middle;
  width: 25px;
}
.battle-record tr td:first-of-type,
.battle-record tr th:first-of-type {
  border-left: 0;
}
.battle-record tr td:last-of-type,
.battle-record tr th:last-of-type {
  border-right: 0;
}
.battle-record__attack div, 
.battle-record__attack--opponent div {
  display: flex;
  gap: var(--small-margin);
  justify-content: center
}
.battle-record__attack__image {
  width: 22px;
}

.battle-record__footer {
  background-color: var(--main-bg-color);
  display: flex;
  padding: var(--small-margin);
    justify-content: center;
  align-items: center;
  flex-direction: row;
  }
  
  .battle-record__column {
  flex-direction: column;
}
.battle-record__footer__button {
  margin: var(--small-margin);
}
.batch-legend--visible {
  display: table;
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .hide-mobile {
    display: none
  }
}
.batch-status-column {
  max-width: 150px;
}

.batch--button {
  padding: 0.5em 1em;
}

.batch--button--active {
  background-color: var(--text-color);
  color: var(--main-bg-color);
}

.batch-button-column {
  min-width: 200px;
}
@media screen and (max-width: 750px) {
  .batch-legend--visible td {
    min-width: auto;
    max-width: auto;
  }
}
  .batch-legend--visible td {
    max-width: 470px;
}