:root {
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 48px;
  --space-xxl: 64px;

  @media (min-width: 600px) {
    --space-xs: 12px;
    --space-s: 18px;
    --space-m: 24px;
    --space-l: 36px;
    --space-xl: 72px;
    --space-xxl: 96px;
  }

  @media (min-width: 1000px) {
    --space-xs: 16px;
    --space-s: 24px;
    --space-m: 32px;
    --space-l: 48px;
    --space-xl: 96px;
    --space-xxl: 128px;
  }
}

.mt-space-xs {
  margin-top: var(--space-xs) !important;
}

.mb-space-xs {
  margin-bottom: var(--space-xs) !important;
}

.mb-space-s {
  margin-bottom: var(--space-s) !important;
}

.mb-space-m {
  margin-bottom: var(--space-m) !important;
}

.mb-space-l {
  margin-bottom: var(--space-l);
}

.mb-space-xl {
  margin-bottom: var(--space-xl);
}

.mb-space-xxl {
  margin-bottom: var(--space-xxl);
}
