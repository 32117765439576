.landing-page__section--tournament_bracket {
  overflow-x: auto;
  padding: 0 32px;
  width: calc(100% + 64px);
}

@media (min-width: 514px) {
  .landing-page__section--tournament_bracket--8,
  .landing-page__section--tournament_bracket--4,
  .landing-page__section--tournament_bracket--2 {
    overflow: hidden;
  }
}

.landing-page__section--tournament_bracket--finals {
  overflow: visible;
  padding-bottom: 64px;
}




.bracket {
  display: flex;
  flex-direction: row;
  padding: 100px 0;
  width: 100%;
}

.bracket--4 {
  padding: 120px 0;
}

.bracket--2 {
  padding: 307px 0 200px;
}

.bracket__round {
  display: flex;
  flex: 1 0 150px;
  flex-direction: column;
  width: 150px;
}

.bracket__round--empty {
  flex-basis: 50px;
  width: 50px;
}

.bracket__round--final-4 {
  flex-grow: 3;
}

.bracket__round--final-4,
.bracket__round--final-2 {
  flex-basis: 150px;
}

.bracket__spacer {
  flex-grow: 1;
  height: 140px;
}

.bracket--4 .bracket__spacer {
  height: 167px;
}

.bracket--2 .bracket__spacer {
  height: 431px;
}

.bracket__spacer:first-child,
.bracket__spacer:last-child {
  flex-grow: 0.5;
  height: 0;
}

.bracket__spacer--connector {
  border: 1px solid currentColor;
  border-left: 0;
}

.bracket__spacer--connector-left {
  border: 1px solid currentColor;
  border-right: 0;
}

.bracket__spacer--connector:last-child,
.bracket__spacer--connector-left:last-child {
  border: 0;
}

.bracket__matchup {
  flex: 0;
  height: 0;
  position: relative;
}

.bracket__matchup:nth-child(2):nth-last-child(2) {
  border-bottom: 1px solid currentColor;
}

.bracket__matchup--final-2 {
  border: 0 !important;
}

.bracket__matchup--finals {
  flex: 1 1 auto;
  height: 31.25vw;
  max-height: 225px;
  width: 100%;
}



.bracket__future {
  bottom: 6px;
  font-size: 12px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}

.bracket__matchup--final-4 .bracket__future.bracket__future--label {
  font-size: 14px;
}

.bracket__matchup--final-4 .bracket__future,
.bracket__matchup--final-2 .bracket__future {
  color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  font-family: Amarante, cursive;
  font-size: 24px;
  transform: translateY(50%);
}

.bracket__matchup--final-4 .bracket__future {
  background: var(--main-bg-color);
  left: 50%;
  right: auto;
  min-width: 130px;
  transform: translate(-50%, 50%);
  width: 25%;
}

.bracket__matchup--final-4 .bracket__future::before,
.bracket__matchup--final-4 .bracket__future::after,
.bracket__matchup--final-2 .bracket__future::before,
.bracket__matchup--final-2 .bracket__future::after {
  border-top: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  content: "";
  height: 8px;
  left: -7px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  transform-origin: 100% 0;
  width: 8px;
}

.bracket__matchup--final-4 .bracket__future::after,
.bracket__matchup--final-2 .bracket__future::after {
  border-left: 1px solid var(--text-color);
  border-right: 0;
  left: auto;
  right: -7px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}



.bracket__matchup .tournament_matchup {
  bottom: 0;
  height: 110px;
  left: 50%;
  max-width: 180px;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}

.bracket--4 .bracket__matchup .tournament_matchup {
  max-width: 215px;
}

.bracket--2 .bracket__matchup .tournament_matchup {
  max-width: none;
}

.bracket__matchup--finals .tournament_matchup {
  max-width: 80vw;
}

.bracket__matchup .warrior__details {
  position: static !important;
}

.bracket__matchup .warrior__image {
  bottom: 0;
  height: auto;
  left: 5px;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  translate: 0 25%;
  width: 140px !important;
}

.bracket--4 .bracket__matchup .warrior__image {
  width: 167px !important;
}

.bracket--2 .bracket__matchup .warrior__image {
  max-width: 431px !important;
  min-width: 180px !important;
  width: 35vw !important;
}

.bracket__matchup--finals .warrior__image {
  max-width: 800px !important;
  min-width: 300px !important;
  width: 85vw !important;
}

.warrior__details--west .warrior__image {
  left: auto;
  right: 5px;
}




.bracket__explainer {
  color: var(--text-color);
  font-size: 10px;
  opacity: 0.5;
  padding: 2px 5px;
}

.bracket__explainer--right {
  text-align: right;
}

.bracket__explainer--finals {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}




.bracket__winner {
  position: relative;
}

.bracket__winner-title {
  color: rgba(0,0,0,0);
  font-family: Amarante, cursive;
  font-size: 11.1111vw;
  letter-spacing: -0.03em;
  line-height: 0.9;
  margin: 0.5em 0 1.5em;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-stroke: 0.02em var(--text-color);
}

.bracket__winner-title span {
  position: relative;
  z-index: 11;
}

.bracket__winner .warrior__image {
  height: auto;
  left: 50%;
  // max-width: 800px !important;
  min-width: 300px !important;
  right: auto;
  top: 30%;
  transform: translate(-50%, -50%);
  width: 85vw !important;
}




.bracket__matchup .warrior__image--badboy {
  scale: 1.05;
  transform-origin: 50% 100%;
  translate: 27% 27.5%;
}
.bracket__matchup .warrior__details--east .warrior__image--badboy {
  translate: -27% 27.5%;
}
.bracket__matchup .warrior__level-5 .warrior__image--badboy {
  scale: none;
  translate: 13% 30%;
}
.bracket__matchup .warrior__level-5.warrior__details--east .warrior__image--badboy {
  translate: -13% 30%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--badboy {
  translate: 25% 27%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--badboy {
  translate: -25% 27%;
}

.bracket__matchup .warrior__image--brawler {
  translate: 23% 30%;
}
.bracket__matchup .warrior__details--east .warrior__image--brawler {
  translate: -23% 30%;
}
.bracket__matchup .warrior__level-5 .warrior__image--brawler {
  scale: 1.25;
  transform-origin: 50% 100%;
  translate: 0 40%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--brawler {
  translate: 23% 35%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--brawler {
  translate: -23% 35%;
}

.bracket__matchup .warrior__image--kickboxer {
  translate: 25% 30%;
}
.bracket__matchup .warrior__details--east .warrior__image--kickboxer {
  translate: -25% 30%;
}
.bracket__matchup .warrior__level-5 .warrior__image--kickboxer {
  translate: 0 25%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--kickboxer {
  translate: 25% 27%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--kickboxer {
  translate: -25% 27%;
}

.bracket__matchup .warrior__image--ninja {
  translate: 28% 28%;
}
.bracket__matchup .warrior__details--east .warrior__image--ninja {
  translate: -28% 28%;
}
.bracket__matchup .warrior__level-5 .warrior__image--ninja {
  translate: 6% 24%;
}
.bracket__matchup .warrior__level-5.warrior__details--east .warrior__image--ninja {
  translate: -6% 24%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--ninja {
  translate: 23% 26%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--ninja {
  translate: -23% 26%;
}

.bracket__matchup .warrior__image--ronin {
  translate: 26% 26%;
}
.bracket__matchup .warrior__details--east .warrior__image--ronin {
  translate: -26% 26%;
}
.bracket__matchup .warrior__level-5 .warrior__image--ronin {
  translate: 6% 32%;
}
.bracket__matchup .warrior__level-5.warrior__details--east .warrior__image--ronin {
  translate: -6% 32%;
}
.bracket__matchup .warrior__level-5 .warrior__image--ronin {
  scale: 1.05;
  transform-origin: 50% 100%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--ronin {
  translate: 25% 29%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--ronin {
  translate: -25% 29%;
}

.bracket__matchup .warrior__image--scholar {
  translate: 29% 32%;
}
.bracket__matchup .warrior__details--east .warrior__image--scholar {
  translate: -29% 32%;
}
.bracket__matchup .warrior__level-5 .warrior__image--scholar {
  translate: 0 28%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--scholar {
  translate: 20% 27%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--scholar {
  translate: -20% 27%;
}

.bracket__matchup .warrior__image--specter {
  translate: 24% 25%;
}
.bracket__matchup .warrior__details--east .warrior__image--specter {
  translate: -24% 25%;
}
.bracket__matchup .warrior__level-5 .warrior__image--specter {
  translate: 13% 25%;
}
.bracket__matchup .warrior__level-5.warrior__details--east .warrior__image--specter {
  translate: -13% 25%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--specter {
  translate: 25% 26%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--specter {
  translate: -25% 26%;
}

.bracket__matchup .warrior__image--tank {
  scale: 1.1;
  transform-origin: 50% 100%;
  translate: 28% 32%;
}
.bracket__matchup .warrior__details--east .warrior__image--tank {
  translate: -28% 32%;
}
.bracket__matchup .warrior__level-5 .warrior__image--tank {
  translate: 0 28%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--tank {
  translate: 25% 29%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--tank {
  translate: -25% 29%;
}

.bracket__matchup .warrior__image--the_don {
  scale: 1.05;
  transform-origin: 50% 100%;
  translate: 16% 28.5%;
}
.bracket__matchup .warrior__details--east .warrior__image--the_don {
  translate: -16% 28.5%;
}
.bracket__matchup .warrior__level-5 .warrior__image--the_don {
  scale: 1.05;
  transform-origin: 50% 100%;
  translate: 6% 27%;
}
.bracket__matchup .warrior__level-5.warrior__details--east .warrior__image--the_don {
  translate: -6% 27%;
}
.bracket__matchup .warrior__details--stunned .warrior__image--the_don {
  translate: 25% 29%;
}
.bracket__matchup .warrior__details--stunned.warrior__details--east .warrior__image--the_don {
  translate: -25% 29%;
}
