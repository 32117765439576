@font-face {
    font-family: DPComic;
    font-style: normal;
    src: url('/fonts/dpcomic.ttf') format('truetype');
}
@font-face {
    font-family: Amarante;
    font-style: normal;
    src: url('/fonts/amarante.ttf') format('truetype');
}
@font-face {
    font-family: 'PressStart2P';
    font-style: normal;
    src: url('/fonts/pressStart.ttf') format('truetype');
}
@font-face {
    font-family: SometypeMono;
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/someType/SometypeMono-Regular.ttf') format('truetype');
}
@font-face {
    font-family: SometypeMono;
    font-style: normal;
    font-weight: bolder;
    src: url('/fonts/someType/SometypeMono-Medium.ttf') format('truetype');
}
@font-face {
    font-family: SometypeMono;
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/someType/SometypeMono-Bold.ttf') format('truetype');
}
@font-face {
    font-family: SometypeMono;
    font-style: italic;
    font-weight: normal;
    src: url('/fonts/someType/SometypeMono-Italic.ttf') format('truetype');
}
@font-face {
    font-family: SometypeMono;
    font-style: italic;
    font-weight: bolder;
    src: url('/fonts/someType/SometypeMono-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: SometypeMono;
    font-style: italic;
    font-weight: bold;
    src: url('/fonts/someType/SometypeMono-BoldItalic.ttf') format('truetype');
}
